//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    //wb
    async loadProducts(items) {
      const products = [];
      for (let i = 0; i < items.length; i++) {
        products.push(
        //this.$store.dispatch("product/getProduct", {
        this.$store.dispatch("product/getProductAuth", {
          p_id: items[i].nm_id,
          mp: this.reportSettings.datasource,
          date: this.formatDate1,
          date2: this.formatDate2,
          fbs: 0
        }));
      }
      for (let i = 0; i < products.length; i++) {
        try {
          const data = await products[i];
          items[i].product = data.title;
          items[i].image = data.image;
        } catch (e) {
          //console.log(e);
        }
      }
      return items;
    },
    async tableLoader({
      query
    }) {
      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbFinancialAnalysisBySku", "connections/getOzFinancialAnalysisBySku", {}, {});
      if (data.type !== "excel") {
        if (this.reportSettings.datasource == "wb") {
          data.items = await this.loadProducts(data.items);
        }
      } else {
        const filter = data.datas[0].sheetFilter;
        const header = data.datas[0].sheetHeader;
        data.datas[0].sheetFilter = filter.filter(item => item !== "product");
        data.datas[0].sheetHeader = header.filter(item => item !== "Товар");
      }
      return data;
    }
  },
  computed: {
    columns() {
      return this.$store.state.connections.marketplace.title === "Wildberries" ? [{
        title: "SKU",
        show: true,
        name: "nm_id",
        type: "text",
        width: 120,
        filter: "text",
        noOverflow: true
      }, {
        title: "Toвар",
        show: true,
        name: "product",
        type: "text",
        width: 260,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "Продажи (шт.)",
        show: true,
        name: "quantity",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Общая себестоимость",
        show: true,
        name: "cost_price",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Продажи (руб.)",
        show: true,
        name: "retail_amount",
        type: "money",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Заказы (шт.)",
        show: true,
        name: "total_orders",
        type: "number",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Процент выкупа",
        show: true,
        name: "f_purchase_percent",
        type: "percent",
        width: 140,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Выручка на артикул",
        show: true,
        name: "revenue",
        type: "money",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Выручка на единицу",
        show: true,
        name: "revenue_per_unit",
        type: "money",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Прибыль на артикул",
        show: true,
        name: "profit",
        type: "money",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Прибыль на единицу",
        show: true,
        name: "profit_per_unit",
        type: "money",
        width: 200,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Маржинальность %",
        show: true,
        name: "margin_percent",
        type: "percent",
        width: 180,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Стоимость логистики, руб",
        show: true,
        name: "cl_delivery_rub",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Комиссия итоговая, руб",
        show: true,
        name: "cl_sales_commission_total",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "К перечислению за товар",
        show: true,
        name: "cl_for_pay",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }]
      // OZON
      : [{
        title: "Toвар",
        show: true,
        name: "item_name",
        type: "text",
        width: 500,
        filter: "text",
        getImage: item => item.image
      }, {
        title: "SKU",
        show: true,
        name: "sku",
        type: "text",
        width: 120,
        filter: "text",
        noOverflow: true
      }, {
        title: "Цена с учётом скидки",
        show: true,
        name: "accruals_for_sale",
        type: "money",
        width: 160,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Итого",
        show: true,
        name: "amount",
        type: "money",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Cтоимость доставки до 2021 года и КГТ",
        show: true,
        name: "delivery_charge",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Дата операции",
        show: true,
        name: "operation_date",
        type: "date",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Номер операции",
        show: true,
        name: "operation_id",
        type: "number",
        width: 120,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Тип операции",
        show: true,
        name: "operation_type_name",
        type: "text",
        width: 420,
        filter: "text",
        noOverflow: true
      },
      /**
      {
          title: "Operation-Type",
          show: true,
          name: "operation_type",
          type: "text",
          width: 420,
          filter: "text",
          noOverflow: true,
      },
      **/

      {
        title: "Номер отправления",
        show: true,
        name: "posting_number",
        type: "text",
        width: 220,
        filter: "text",
        noOverflow: true
      }, {
        title: "Плата за возвраты и отмены до февраля 2021 года и КГТ",
        show: true,
        name: "return_delivery_charge",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }, {
        title: "Комиссия",
        show: true,
        name: "sale_commission",
        type: "money",
        width: 220,
        filter: "numberRange",
        noOverflow: true
      }];
    },
    formatDate1() {
      let split = this.reportSettings.date.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    },
    formatDate2() {
      let split = this.reportSettings.date2.split("-");
      return split[2] + "-" + split[0] + "-" + split[1];
    }
  }
};