//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import EntityCharts from "@/components/EntityCharts";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import AppSelect from "@/components/AppSelect.vue";
import AppTabs from "@/components/AppTabs.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import SalesGeographyTable from "@/components/Connections/SalesGeographyTable";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import MyShopsMixin from "@/mixins/MyShops";
import HasTokens from "@/components/HasTokens";
import { getEntityCellsConfig } from "@/utils/enums";
import FinancialAnalysisGeneralTable from "@/components/Connections/FinancialAnalysisGeneralTable";
import FinancialAnalysisSkuTable from "@/components/Connections/FinancialAnalysisSkuTable";
import FinancialAnalysisExpensesTable from "@/components/Connections/FinancialAnalysisExpensesTable";
import { dateRangeBoundaries,
// dateRangeFromDate,
today, parseDate, FORMAT } from "@/utils/dates";
const tableComponents = {
  general: FinancialAnalysisGeneralTable,
  sku: FinancialAnalysisSkuTable,
  expenses: FinancialAnalysisExpensesTable
};
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "Финансовый анализ"
  },
  data() {
    const cells = getEntityCellsConfig("financialAnalysisWb");
    const localStorageCalendar = JSON.parse(localStorage.getItem("calendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("connections/updateCalendar", localStorageCalendar);
    }
    const tabs_tables = [{
      id: "general",
      title: "Общий отчет"
    }, {
      id: "sku",
      title: "Анализ товаров"
    }];
    return {
      cells,
      tabs_tables,
      tabs_tables_model: tabs_tables[0],
      calendarInternal: null,
      calendar: this.$store.state.connections.calendar,
      calendarBoundaries: [],
      step: "week"
    };
  },
  mounted() {
    this.marketplace = this.select_marketplace_items[0];
  },
  async created() {
    await this.getCalendarBoundaries();
    this.setCalendar();
  },
  methods: {
    setCalendar() {
      var _this$calendarBoundar;
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      let start = parseDate(lastAvailableDate).subtract(1, this.step).startOf(this.step);
      let end = parseDate(lastAvailableDate).subtract(1, this.step).endOf(this.step);
      if (this.step === "week") {
        start = start.add(1, "day").format(FORMAT);
        end = end.add(1, "day").format(FORMAT);
      } else {
        start = start.format(FORMAT);
        end = end.format(FORMAT);
      }
      const range = [start, end];
      this.calendar = dateRangeBoundaries(range, this.calendarBoundaries);
    },
    async getCalendarBoundaries() {
      let dates = [];
      if (this.marketplace.title === "Wildberries") {
        dates = await this.$store.dispatch("connections/getWbFinancialAnalysisBorderDates", {
          tokens: this.reportSettings.tokens
        });
      }
      //if (this.marketplace.title === "OZON") {
      else {
        //if( this.marketplace.title.indexOf("OZON")!=-1 ){
        dates = await this.$store.dispatch("connections/getOzFinancialAnalysisBorderDates", {
          tokens: this.reportSettings.tokens
        });
      }
      this.calendarBoundaries = [dates.data.date_from, dates.data.date_to];
    },
    getTableComponent(id) {
      return tableComponents[id];
    },
    async loadOverview() {
      var _total;
      let dynamic = null;
      let total = null;
      if (this.marketplace.title === "Wildberries") {
        dynamic = await this.$store.dispatch("connections/getWbHighlightsByDays", this.reportSettings);
        total = await this.$store.dispatch("connections/getWbFinancialAnalysisTotal", this.reportSettings);
      }
      //if (this.marketplace.title === "OZON") {
      else {
        //if( this.marketplace.title.indexOf("OZON")!=-1 ){
        dynamic = await this.$store.dispatch("connections/getOzHighlightsByDays", this.reportSettings);
        //console.log('245:oz: dynamic=',dynamic)

        total = await this.$store.dispatch("connections/getOzFinancialAnalysisTotal", this.reportSettings);
        console.log('251:oz: total=', total);
      }
      let res = {};
      // const keys = Object.keys(dynamic?.data[0]);
      (_total = total) === null || _total === void 0 || _total.data.forEach(item => {
        var _this$cells$item$id;
        const number = (_this$cells$item$id = this.cells[item.id]) !== null && _this$cells$item$id !== void 0 && _this$cells$item$id.unit ? Number(item.value).toFixed(0) : Number(item.value);
        res = {
          ...res,
          [item.id]: {
            total: number,
            dynamic: dynamic.data.map(i => {
              return i[`${item.id}`];
            })
          }
        };
      });
      return res;
    }
  },
  computed: {
    select_marketplace_items_new() {
      //console.log("FinancialAnalysis.vue.277: this.select_marketplace_items=", this.select_marketplace_items);

      const select_marketplace_items_new = this.select_marketplace_items;
      // select_marketplace_items_new[1].title = "OZON (βeta)";
      // select_marketplace_items_new[1].disabled = true;
      return select_marketplace_items_new;
    },
    fileName() {
      const split1 = this.reportSettings.date.split("-");
      const split2 = this.reportSettings.date2.split("-");
      const dates = [split1[1] + "-" + split1[0] + "-" + split1[2], split2[1] + "-" + split2[0] + "-" + split2[2]];
      return `${this.marketplace.title} Финансовый анализ ${dates[0]} - ${dates[1]}`;
    },
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });

      //console.log("FinancialAnalysis.vue.304: this.selectedTokens=", this.selectedTokens);

      return {
        date: dates[0],
        date2: dates[1],
        tokens: this.selectedTokens.map(item => item.id),
        datasource: this.marketplace.id
      };
    }
  },
  watch: {
    async selectedTokens() {
      await this.getCalendarBoundaries();
    },
    marketplace(val) {
      if (val.title === "Wildberries") {
        this.tabs_tables = [{
          id: "general",
          title: "Общий отчет"
        }, {
          id: "sku",
          title: "Анализ товаров"
        }];
        this.step = "week";
        this.cells = getEntityCellsConfig("financialAnalysisWb");
      }
      //if (val.title === "OZON (beta)") {
      else {
        //if( this.marketplace.title.indexOf("OZON")!=-1 ){
        this.tabs_tables = [{
          id: "general",
          title: "Отчет о реализации"
        }, {
          id: "sku",
          title: "Отчет по заказам и начислениям"
        }, {
          id: "expenses",
          title: "Суммы по расходам"
        }];
        this.step = "month";
        this.cells = getEntityCellsConfig("financialAnalysisOzon");
        console.log('351:oz: this.cells=', this.cells);
      }
      this.tabs_tables_model = this.tabs_tables[0];
      this.setCalendar();
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    SalesGeographyTable,
    HasTokens,
    DataLoading,
    DataTableActions,
    EntityCharts,
    AppTabs
  }
};